import bg1 from "../src/assets/images/bg/removeBgImg.svg";
import bg2 from "../src/assets/images/landingpage/addImageCategories.svg";
import bg3 from "../src/assets/images/landingpage/addedImageBgCategories.svg";
import bg4 from "../src/assets/images/landingpage/removeImageCategories.svg";
import bg5 from "../src/assets/images/landingpage/addedBackground.svg";
import bg6 from "../src/assets/images/landingpage/Api&tools.svg";
import cercle from "../src/assets/images/landingpage/cercle.png";
import BackgroundRemover from "../src/components/landingpage/SectionOne";
import Sectiontwo from "../src/components/landingpage/SectionTwo";
import PricingSection from "../src/components/landingpage/PricingSection";
import { NextSeo } from "next-seo";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import {
  getallsubscriptions,
  pricinglist,
} from "../src/Redux/Subscription/SubscriptionSlice";
import NewsLetter from "../src/components/dashboard/newsletter";
import Image from "next/image";
import BlogSection from "../src/components/landingpage/BlogSection";
// import SEO from '../../next-seo.config'; // Vérifiez le chemin
import SEO from '../next-seo.config';
const Home = () => {

  const images = [
    { url: bg2, label: "Original" },
    { url: bg3, label: "Background removed" },
    { url: bg4, label: "New background" },
  ];
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(getallsubscriptions());
    };
  }, [dispatch]);
  const subscriptions = useSelector(pricinglist);

  const sectionRefs = useRef([]);
  useEffect(() => {
    const currentSections = sectionRefs.current;
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
          observer.unobserve(entry.target);
        }
      });
    }, options);

    currentSections.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      currentSections.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);
  return (
    <><div className="homepage-background">
      <div className="homepage-background-content">
      </div>
    </div>
    <div className="indexpage">
      {/* <NextSeo
        title="Pixeloop"
        description="Découvrez notre outil puissant pour supprimer et ajouter des arrière-plans,
        redimensionner vos images et intégrer des API dans Prestashop. Simplifiez vos processus et optimisez vos visuels en un clin d'œil."
        keywords={[
          "supprimer fond image Prestashop",
          "ajouter fond image Prestashop",
          "redimensionner image Prestashop",
          "API Prestashop",
        ]}
        openGraph={{
          type: "article",
          description:
            "Découvrez notre outil puissant pour supprimer et ajouter des arrière-plans,redimensionner vos images et intégrer des API dans Prestashop. Simplifiez vos processus et optimisez vos visuels en un clin d'œil.",
          url: "https://www.votre-site.com/",
          title:
            "Suppression et Ajout d'Arrière-Plan, Redimensionnement, et Intégration API Prestashop",
        }}
      /> */}
      <NextSeo
  title="Pixeloop - Ajouter un Arrière-Plan avec IA pour Images"
  description="Ajoutez facilement un nouvel arrière-plan à vos images avec Pixeloop."
  {...SEO} // Cela appliquera les paramètres par défaut
  openGraph={{
    ...SEO.openGraph,
    title: "Ajouter un Arrière-Plan à vos Images - Solution IA Pixeloop pour E-commerce",
    description: "Avec Pixeloop, ajoutez des arrière-plans uniques à vos images en quelques secondes.",
    url: "{BASE_URL}/add-background",
  }}
/>


      <div ref={(el) => (sectionRefs.current[0] = el)} className="section">
        <BackgroundRemover
          title={
            <div className="inline-title">
              <span>
                Instantly Enhance Your Images{" "}
                <span className="text-style">
                  with AI
                  <span className="responsive-circle">
                    <Image src={cercle} alt="Cercle" />
                  </span>
                </span>
              </span>
            </div>
          }
          description={
            <>
              Pixeloop delivers flawless results—remove backgrounds, add custom
              scenes, resize, and more, all without design skills or high-end
              gear. Perfect for elevating your online store, social media, and
              more, while maintaining top quality.
            </>
          }
          buttontext="Remove Background Now "
          imageUrl={bg1}
          imgPosition="right"
          titleColor="#000000"
          buttonColor="primary"
          buttonBgColor="transparent"
          btnUrl="use-case/remove-background"
        />
      </div>
      <div className="home-container">
        <div
          ref={(el) => (sectionRefs.current[1] = el)}
          className="section"
        >
          <BackgroundRemover
            title="Add the Perfect Background"
            description="Looking for a unique look? Add a custom background to give your images character.
             Whether it’s a professional setting or a creative scene, our tool offers complete flexibility to personalize every detail."
            buttontext="Add Background Now"
            imageUrl={bg5}
            imgPosition="left"
            titleColor="#000000"
            buttonColor="primary"
            buttonBgColor="transparent"
            btnUrl="use-case/add-background"
          />
        </div>
        <div
          ref={(el) => (sectionRefs.current[2] = el)}
          className="section"
        >
          <Sectiontwo
            title={
              <>
                {" "}
                <span className="inline-text"> Just </span>{" "}
                <span className="text-style">
                  imagine!
                  <span className="responsive-circle responsive-circle-100">
                    <Image src={cercle} alt="Cercle" />
                  </span>
                </span>
              </>
            }
            images={images}
            description="Our online background remover instantly identifies the subject in 
                   any photo, delivering a smooth and precise cutout"
          />
        </div>
        <div
          ref={(el) => (sectionRefs.current[3] = el)}
          className="section"
        >
          <BackgroundRemover
            title="Unlock Seamless Integration"
            description={
              <>
                Pixeloop is your gateway to effortless integration with any
                eCommerce platform. With powerful APIs and plugins, connect
                Pixeloop to your system easily, enhancing services with minimal
                effort. Enjoy robust functionality, flexibility, and a smooth
                integration that unlocks new business possibilities. Elevate
                your platform with Pixeloop.
              </>
            }
            buttontext="Try Now Our Integration"
            imageUrl={bg6}
            imgPosition="right"
            titleColor="#00000"
            buttonColor="primary"
            buttonBgColor="transparent"
            btnUrl="use-case/remove-background"
          />
        </div>
        <div
          ref={(el) => (sectionRefs.current[4] = el)}
          className="section"
        >
          <PricingSection
            title={
              <>
                {" "}
                <span>
                  Our Pricing{" "}
                  <span className="text-style">
                    Packages
                    <span className="responsive-circle my-3">
                      <Image src={cercle} alt="Cercle" />
                    </span>
                  </span>
                </span>
              </>
            }
            description="At Pixeloop, we offer flexible packages tailored to your needs and budget. 
            No matter your goals, we ensure the best experience for you."
            pricingPlans={subscriptions}
          />
        </div>

        <div ref={(el) => (sectionRefs.current[5] = el)} className="section">
          <BlogSection
            title={
              <>
                {" "}
                <span className="inline-text">Our </span>{" "}
                <span className="text-style">
                  blogs
                  <span className="responsive-circle responsive-circle-50 ">
                    <Image src={cercle} alt="Cercle" />
                  </span>
                </span>
              </>
            }
            images={images}
            description={
              <>
                Stay ahead in the game of digital creativity by following our
                blog for expert insights,<br></br> innovative techniques, and
                success stories using our AI background removal tools.
              </>
            }
          />
        </div>
      </div>

      <div
        ref={(el) => (sectionRefs.current[6] = el)}
        className="section d-flex justify-content-center newslater-blog"
      >
        <NewsLetter />
      </div>
    </div>
    </>
  );
}
const BASE_URL = process.env.NEXT_BASE_URL;

Home.seo = {
  title:
    "Pixeloop - AI-Powered Background Removal and Addition",
  description:
    "Optimize your images in a flash with Pixeloop. Use our AI tool to remove and add backgrounds, resize images, and integrate APIs into PrestaShop. The ideal solution for e-commerce sites, your visuals have never been so impactful.",
  additionalMetaTags: [
    {
      name: "keywords",
      content:
      "AI background removal tool ,AI background addition tool ,background removal tool for PrestaShop ,PrestaShop API image integration ,online image resizing ,e-commerce image editing ,background remover tool , AI image editing",
    },
    {
      name: "robots",
      content: "index,follow",
    },
  ],
  openGraph: {
    type: "website",
    description:
      "With Pixeloop, simplify your images for your e-commerce site or PrestaShop with AI-powered background removal and addition. Integrate our tools to enhance your visuals and boost conversions.",
    url: {BASE_URL},
    title:
      "Pixeloop - AI-Powered Background Removal and Addition for E-commerce and PrestaShop",
      images: [
        {
          url: {BASE_URL}+"/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FremoveBgImg.0a20af62.svg&w=640&q=75",
          alt: "Pixeloop - Remove image",
        },
        {
          url: {BASE_URL}+"/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FaddedBackground.f1e48532.svg&w=640&q=75",
          alt: "Pixeloop - Add background",
        },
    
        {
          url:{BASE_URL}+ "/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FaddImageCategories.7dec1c2a.svg&w=384&q=75",
          alt: "Pixeloop - Resize Image Categories",
        },
        {
          url: {BASE_URL}+"/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FaddedImageBgCategories.aa46f1a7.svg&w=384&q=75",
          alt: "Pixeloop - Add Image Categories",
        },
        {
          url: {BASE_URL}+"/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FremoveImageCategories.17c2e005.svg&w=384&q=75",
          alt: "Pixeloop - Remove Image Categories",
        },
    
        {
          url: {BASE_URL}+"/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FApi%26tools.b48ecfa4.svg&w=640&q=75",
          alt: "Pixeloop - Api and tools",
        },
        {
          url: {BASE_URL}+"/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FpixeloopLogo.c60a4072.svg&w=256&q=75",
          alt: "Pixeloop - logo",
        },
        {
          url: {BASE_URL}+"/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FpixeloopWhiteLogo.390751d6.svg&w=256&q=75",
          alt: "Pixeloop - white logo",
        },
    
        
        
      ],
  },
};
export default Home;
